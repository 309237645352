/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"


function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { social } = data.site.siteMetadata
        return (
          <Container>
            <p>
              Merhaba, welcome to my blog....
              <br />
              Places you can follow me, {' '}
              <a href={`https://twitter.com/${social.twitter}`}>Twitter
              </a>{', '}
              <a href={`https://instagram.com/${social.instagram}`}>
                Instagram
              </a>
            </p>
          </Container>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/photo.jpeg/" }) {
      childImageSharp {
        fixed(width: 125, height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          instagram
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
`

export default Bio
