import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Bio from '../components/bio'
import Tags from "../components/Tags";

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const blogTitle = data.site.siteMetadata.blogTitle
    const posts = data.allMdx.edges
    return (
      <Layout location={this.props.location} title={blogTitle}>
        <SEO title="All posts" />
        <Bio />
        <div style={{ margin: "20px 0 40px" }}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const tags = node.frontmatter.tags
            console.log(node.frontmatter)
            return (
              <div key={node.fields.slug} style={{
                backgroundColor: '#E6E6EA', marginTop: '7px', padding: '0 15px', border: '1px solid #10375c', borderRadius: '10px', borderCollapse: 'separate'
              }}>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 2),
                    marginTop: rhythm(1 / 2),
                  }}
                >
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/blog${node.fields.slug}`}
                  >
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
                {tags && tags.length > 0 ? ` - ` : ``}
                <Tags children={tags} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
                <Link
                  style={{ boxShadow: `none` }}
                  to={`/blog${node.fields.slug}`}
                >
                  <b>Click to read!</b>
                </Link>
              </div>
            )
          })}
        </div>

        <Link to="/" style={{ textDecoration: 'none' }}>Back to home page!</Link>

      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        blogTitle
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
